import React, { useEffect, useState } from "react";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

export interface AvatarWithUsernameProps extends AvatarProps {
  username: string;
  badgeVariant?: "dot" | "standard";
  size?: "small" | "standard";
  subTitle?: string;
  hiddenName?: boolean;
  lastActive?: string;
  unreadCount?: number;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, size: "small" | "standard") {
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: size === "small" ? 10 : 20,
      height: size === "small" ? 24 : 42,
      width: size === "small" ? 24 : 42,
      color: "white",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const AvatarWithUsername: React.FC<AvatarWithUsernameProps> = ({
  username,
  subTitle,
  badgeVariant = "dot",
  size = "standard",
  hiddenName = false,
  unreadCount = 0,
  ...props
}) => {
  const [title, setTitle] = useState("");
  const [secondaryTitle, setSecondaryTitle] = useState("");

  useEffect(() => {
    setTitle(username);
  }, [username]);

  useEffect(() => {
    setSecondaryTitle(subTitle ?? "");
  }, [subTitle]);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {/* <Avatar {...props} title="RV" /> */}
      <Badge badgeContent={unreadCount} color="error">
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant={badgeVariant}
        >
          <Avatar {...stringAvatar(username, size)} {...props} />
        </StyledBadge>
      </Badge>

      {!hiddenName && (
        <Box sx={{ display: "grid", alignItems: "center" }}>
          <Typography variant="body1" fontWeight="700">
            {title}
          </Typography>
          <Typography variant="caption">{subTitle}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default AvatarWithUsername;
