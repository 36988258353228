import { ExecGraphQL } from "graphql/client/client";
import { LIST_CHATS } from "graphql/gql/chat.gql";

export const listChats = async (variables: any) => {
  const result = await ExecGraphQL(
    LIST_CHATS,
    {
      input: { ...variables },
    },
    null
  );
  return result;
};
