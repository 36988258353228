import { FC } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { DialogProps } from "interfaces/common.interfaces";

interface ServiceWorkerUpdateNotificationProps extends DialogProps {
  reloadPage: () => void;
}

const ServiceWorkerUpdateNotification: FC<ServiceWorkerUpdateNotificationProps> = ({ reloadPage, open }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => null}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"New update available"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please confirm the update to continue using the Delivery App!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" size="small" onClick={reloadPage}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ServiceWorkerUpdateNotification;
