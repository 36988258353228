// db.ts
import Dexie, { Table } from "dexie";
import { Customer } from "interfaces/customer.interface";
import { Delivery } from "interfaces/delivery.interface";
import { Fresh } from "interfaces/fresh.interface";
import { InitialCheck } from "interfaces/initial-check.interface";
import { Item } from "interfaces/item.interface";
// import { Mail } from 'interfaces/mail.interface';
import { User } from "interfaces/user.interface";
import { ApiBackup } from "../interfaces/api-backup.interface";
import { LOCAL_DB_NAME } from "./local.db.conf";
import { PushNotificationSubscription } from "interfaces/push-notification-subscription.interface";
import { PushNotificationSubscriptionSwError } from "interfaces/push-notification-subscription-sw-error.interface";
import { Notification } from "interfaces/notification.interface";

export class LocalDatabase extends Dexie {
  // tables
  customers!: Table<Customer>;
  deliveries!: Table<Delivery>;
  items!: Table<Item>;
  users!: Table<User>;
  initial_checks!: Table<InitialCheck>;
  freshes!: Table<Fresh>;
  // mails!: Table<Mail>;
  pushNotificationSubscription!: Table<PushNotificationSubscription>;
  pushNotificationSubscriptionSwError!: Table<PushNotificationSubscriptionSwError>;
  notifications!: Table<Notification>;

  // save anything you want
  api_backups!: Table<ApiBackup>;

  constructor() {
    super(LOCAL_DB_NAME);
    this.version(8).stores({
      // sync tables
      customers:
        "_id, area, customerName, accountCode, address, phoneNumber, deliveryInstructions, listNumber, backendSyncStatus",
      deliveries:
        "_id, area, customer, customerCode, documentType, documentNumber, deliveryDate, status, secondDeliveryDate, secondDeliveryStatus, reference, arrivalTime, departureTime, truckTemperature, signeeName, signature, signDateAndTime, photo, driverNotes, PODType, issueFlag, items, deliveryInstructions, backendSyncStatus, driver, truckRegistration, formattedDate",
      items:
        "_id, documentNumber, code, unit, supply, type, issue, secondDelivery, missingQuantity, comments, secondDeliveryComments, description, unitPrice, photo, reasonCode, backendSyncStatus",
      users:
        "_id, email, name, role, image, truckRegistration, areas, last_name, defaultArea, backendSyncStatus",
      initial_checks:
        "_id, driver, date, currentArea, chosenAreas, truckRegistration, securityCheck, cleaningCheck, startKMS, endKMS, dailyComments, browser, deviceId, deviceOS, latitude, longitude, tracking, lastActive, createdAt, updatedAt, backendSyncStatus",
      freshes: "success, message, backendSyncStatus",
      // mails: 'success, message, backendSyncStatus',
      pushNotificationSubscription: "_id, user, endpoint, p256dhKey, auth",
      pushNotificationSubscriptionSwError: "user, error, errorMessage",
      notifications:
        "_id, notificationType, message, area, driver, deliveryCount, downloadedCount, createdAt, read, deliveries, formattedDate",

      // save raw data
      api_backups: "name, value", // Primary key and indexed props
    });
  }
}

export const db = new LocalDatabase();

export const TABLE_NAMES = [
  "customers",
  "deliveries",
  "items",
  "users",
  "initial_checks",
  "freshes",
  // 'mails',
  "api_backups",
  "notifications",
] as const;

export type TableName = (typeof TABLE_NAMES)[number];
