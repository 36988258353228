import { FC } from "react";
import SvgIcon from "@mui/material/SvgIcon";

interface Props {
  width?: number;
  height?: number;
}

const TrustmileLogo: FC<Props> = ({ width = 210, height = 80 }) => {
  return (
    <SvgIcon sx={{
      width: '100%', // Ensures the SVG takes the full width of its container
      height: 'auto', // Maintains aspect ratio
    }}>
      <svg
        id="Group_66"
        data-name="Group 66"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 945.666 206.872"
      >
        <defs>
          <clipPath id="clipPath">
            <rect id="Rectangle_35" data-name="Rectangle 35" width="500.666" height="100.872" fill="none" />
          </clipPath>
        </defs>
        <g id="Group_66-2" data-name="Group 66" clipPath="url(#clip-path)">
          <path
            id="Path_97"
            data-name="Path 97"
            d="M938.442,125.368a14.03,14.03,0,0,1,5.3,5.3,15.4,15.4,0,0,1-.042,14.991,14.486,14.486,0,0,1-5.36,5.382,15.214,15.214,0,0,1-14.968,0,14.414,14.414,0,0,1-5.319-5.36,15.249,15.249,0,0,1,0-14.908,14.347,14.347,0,0,1,5.338-5.357,14.739,14.739,0,0,1,7.538-1.968,14.9,14.9,0,0,1,7.516,1.924m-1.507,23.3a11.607,11.607,0,0,0,4.311-4.394,12.877,12.877,0,0,0,.042-12.25,11.246,11.246,0,0,0-4.248-4.292,12.02,12.02,0,0,0-6.114-1.567,11.883,11.883,0,0,0-6.114,1.589,11.4,11.4,0,0,0-4.269,4.356,12.811,12.811,0,0,0,0,12.224,11.457,11.457,0,0,0,4.247,4.356,12.317,12.317,0,0,0,12.145-.022m.919-9.754a5.133,5.133,0,0,1-2.28,1.987l3.558,5.443h-4.356l-3.055-4.69h-2.89v4.69h-4.352V130.1h7.073a7.871,7.871,0,0,1,5.192,1.567,5.2,5.2,0,0,1,1.886,4.209,5.73,5.73,0,0,1-.776,3.037m-6.594-.525a3.583,3.583,0,0,0,2.283-.649,2.241,2.241,0,0,0,.817-1.863,2.308,2.308,0,0,0-.817-1.885,3.515,3.515,0,0,0-2.283-.668h-2.47v5.065Z"
            fill="#3939ff"
          />
          <path
            id="Path_98"
            data-name="Path 98"
            d="M289.378,87.009v43.95q0,4.467,2.09,6.412t7.132,1.945h10.087v13.689H295.718q-11.1,0-17-5.187t-5.908-16.859V87.009h-9.366v-13.4h9.366V53.867h16.571V73.608h19.309v13.4Z"
            fill="#1a345b"
          />
          <path
            id="Path_99"
            data-name="Path 99"
            d="M349.826,75.7a28.347,28.347,0,0,1,14.192-3.386v17H359.84q-9.657,0-14.626,4.9t-4.971,17v41.788H323.816v-79.4h16.427V85.136a25.782,25.782,0,0,1,9.583-9.439"
            fill="#1a345b"
          />
          <path
            id="Path_100"
            data-name="Path 100"
            d="M484.7,150.915a29.461,29.461,0,0,1-11.743-9.222,22.984,22.984,0,0,1-4.612-13.041h17a11.585,11.585,0,0,0,4.827,8.43,17.522,17.522,0,0,0,11.024,3.386q6.916,0,10.735-2.666a8.044,8.044,0,0,0,3.818-6.844,7.033,7.033,0,0,0-4.251-6.629,79.8,79.8,0,0,0-13.473-4.755,129.514,129.514,0,0,1-14.554-4.755,25.487,25.487,0,0,1-9.726-7.061q-4.107-4.755-4.107-12.536a19.553,19.553,0,0,1,3.746-11.6,24.948,24.948,0,0,1,10.736-8.285,40.1,40.1,0,0,1,16.067-3.026q13.542,0,21.83,6.844t8.862,18.66H514.452a11.7,11.7,0,0,0-4.323-8.5q-3.889-3.168-10.519-3.17-6.484,0-9.943,2.45a7.588,7.588,0,0,0-3.458,6.484,7.017,7.017,0,0,0,2.306,5.332,16.39,16.39,0,0,0,5.62,3.386q3.312,1.226,9.8,3.1a106.678,106.678,0,0,1,14.194,4.683,26.457,26.457,0,0,1,9.582,6.989q4.032,4.612,4.179,12.248a20.541,20.541,0,0,1-3.747,12.1,24.857,24.857,0,0,1-10.59,8.358,39.486,39.486,0,0,1-16.068,3.025,40.02,40.02,0,0,1-16.787-3.386"
            fill="#1a345b"
          />
          <path
            id="Path_101"
            data-name="Path 101"
            d="M568.776,87.009v43.95q0,4.467,2.09,6.412T578,139.316h10.087v13.689h-12.97q-11.1,0-17-5.187T552.2,130.959V87.009h-9.365v-13.4H552.2V53.867h16.572V73.608h19.309v13.4Z"
            fill="#1a345b"
          />
          <path
            id="Path_102"
            data-name="Path 102"
            d="M716.114,76.2a28.116,28.116,0,0,1,11.671,11.527q4.248,7.641,4.251,18.445v46.831H715.753V108.623q0-10.661-5.331-16.354t-14.554-5.692q-9.223,0-14.626,5.692t-5.4,16.354v44.382H659.556V108.623q0-10.661-5.332-16.354T639.67,86.577q-9.223,0-14.625,5.692t-5.4,16.354v44.382H603.214v-79.4h16.427v9.079a27.323,27.323,0,0,1,10.23-7.638,32.465,32.465,0,0,1,13.258-2.738,35.313,35.313,0,0,1,17,4.035,27.59,27.59,0,0,1,11.527,11.672A26.623,26.623,0,0,1,682.9,76.563a33.248,33.248,0,0,1,16.428-4.252A35.612,35.612,0,0,1,716.114,76.2"
            fill="#3939ff"
          />
          <path
            id="Path_103"
            data-name="Path 103"
            d="M753.793,60.063a10.787,10.787,0,0,1,0-14.986,10.17,10.17,0,0,1,7.492-3.026,10,10,0,0,1,7.35,3.026,10.793,10.793,0,0,1,0,14.986,10.008,10.008,0,0,1-7.35,3.026,10.173,10.173,0,0,1-7.492-3.026m15.561,92.942H752.928v-79.4h16.426Z"
            fill="#3939ff"
          />
          <rect
            id="Rectangle_34"
            data-name="Rectangle 34"
            width="16.426"
            height="106.631"
            transform="translate(790.969 46.374)"
            fill="#3939ff"
          />
          <path
            id="Path_104"
            data-name="Path 104"
            d="M901.2,119.431H840.537q.719,9.51,7.06,15.274a22.285,22.285,0,0,0,15.563,5.764q13.254,0,18.733-11.1h17.723a35.776,35.776,0,0,1-13.04,17.941q-9.439,6.99-23.416,6.988a40.535,40.535,0,0,1-20.39-5.115,36.369,36.369,0,0,1-14.122-14.41,43.957,43.957,0,0,1-5.115-21.542A45,45,0,0,1,828.5,91.692a34.965,34.965,0,0,1,13.978-14.337,41.594,41.594,0,0,1,20.678-5.044,40.452,40.452,0,0,1,20.029,4.9,34.634,34.634,0,0,1,13.689,13.761,41.458,41.458,0,0,1,4.9,20.389,51.621,51.621,0,0,1-.575,8.07M884.63,106.174q-.145-9.078-6.485-14.554a23.193,23.193,0,0,0-15.706-5.475,21.092,21.092,0,0,0-14.553,5.4q-6.052,5.4-7.206,14.626Z"
            fill="#3939ff"
          />
          <path
            id="Path_105"
            data-name="Path 105"
            d="M414.64,154.144a36.041,36.041,0,0,1-36-36V72.311h15.171v45.833a20.829,20.829,0,1,0,41.658,0V72.311H450.64v45.833a36.041,36.041,0,0,1-36,36"
            fill="#1a345b"
          />
          <path
            id="Path_106"
            data-name="Path 106"
            d="M100.857,176.769l-13.716-7.785a11.412,11.412,0,0,1-5.778-9.88L81.1,118.292A27.854,27.854,0,0,0,67.172,94.279L52.95,86.068l.316,80.947A22.2,22.2,0,0,0,64.416,186.2l30.895,17.725a22.2,22.2,0,0,0,22.29-.115l31.056-18.248a22.2,22.2,0,0,0,10.951-19.255l-.088-16.556-27.929,15.965-19.469,11.05a11.409,11.409,0,0,1-11.265,0"
            fill="#3939ff"
          />
          <path
            id="Path_107"
            data-name="Path 107"
            d="M31.793,39.28l13.6-7.986a11.411,11.411,0,0,1,11.445-.064L92.315,51.406a27.852,27.852,0,0,0,27.759-.053L134.3,43.142l-70.26-40.2A22.2,22.2,0,0,0,41.843,3L11.046,20.9A22.2,22.2,0,0,0,0,40.259L.275,76.278a22.2,22.2,0,0,0,11.2,19.112l14.382,8.2L26,71.421l.164-22.385a11.412,11.412,0,0,1,5.633-9.756"
            fill="#3939ff"
          />
          <path
            id="Path_108"
            data-name="Path 108"
            d="M185.206,48.684l.116,15.771a11.412,11.412,0,0,1-5.668,9.944L144.443,95.035A27.852,27.852,0,0,0,130.609,119.1v16.422l26.976-15.715,42.968-25.031A22.2,22.2,0,0,0,211.6,75.525l-.1-35.617a22.2,22.2,0,0,0-11.246-19.247L168.923,2.89a22.2,22.2,0,0,0-22.152.143l-14.292,8.354,27.789,16.206,19.3,11.335a11.415,11.415,0,0,1,5.634,9.756"
            fill="#3939ff"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default TrustmileLogo;
