import { OperationMapType } from 'interfaces/api-backup.interface';

export const API_OPERATION_MAP: {
  [key: string]: OperationMapType;
} = {
  // customers
  createCustomer: { TableName: 'customers', TYPE: 'create' },
  updateCustomer: { TableName: 'customers', TYPE: 'update' },
  getCustomer: { TableName: 'customers', TYPE: 'get' },
  listCustomers: { TableName: 'customers', TYPE: 'list' },

  // deliveries
  createDelivery: { TableName: 'deliveries', TYPE: 'create' },
  getDelivery: { TableName: 'deliveries', TYPE: 'get' },
  listDeliveries: { TableName: 'deliveries', TYPE: 'list' },
  updateDeliveryNote: { TableName: 'deliveries', TYPE: 'update' },
  updateDelivery: { TableName: 'deliveries', TYPE: 'update' },
  adminListDeliveries: { TableName: 'deliveries', TYPE: 'list' },
  searchDeliveries: { TableName: 'deliveries', TYPE: 'list' },
  syncDeliveries: { TableName: 'deliveries', TYPE: 'list' },

  // initial checks
  // createInitialCheck: { TableName: 'initial_checks', TYPE: 'create' },
  updateInitialCheck: { TableName: 'initial_checks', TYPE: 'update' },
  getInitialCheck: { TableName: 'initial_checks', TYPE: 'get' },

  // items
  createItem: { TableName: 'items', TYPE: 'create' },
  updateItem: { TableName: 'items', TYPE: 'update' },

  // users
  registerUser: { TableName: 'users', TYPE: 'create' },
  registerUserAdmin: { TableName: 'users', TYPE: 'create' },
  loginUser: { TableName: 'users', TYPE: 'get' },
  getUser: { TableName: 'users', TYPE: 'get' },
  getUsers: { TableName: 'users', TYPE: 'list' },
  updateUserData: { TableName: 'users', TYPE: 'update' },
  deleteUser: { TableName: 'users', TYPE: 'delete' },
  deleteManyUsers: { TableName: 'users', TYPE: 'delete' },
  resetPassword: { TableName: 'users', TYPE: 'update' },
  forgotPassword: { TableName: 'users', TYPE: 'create' },

  //notifications
  // listNotifications: { TableName: 'notifications', TYPE: 'list' },
  // createNotification: { TableName: 'notifications', TYPE: 'create' },
};
