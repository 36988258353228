import { Box, Typography } from '@mui/material';
import React from 'react';
import { useUserStore } from 'state/user.store';
import { GooglePlayButton, AppStoreButton } from 'react-mobile-app-button';
import TrustmileLogo from './Trustmile.icon';

const ChangeLinkMessage = () => {
  const { user } = useUserStore();
  
  return (
    <Box
      sx={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
    >

      {/* Adjust the size and styling of the logo */}
      <Typography color="black" variant="h4">
        We are excited to announce that our new Mobile app is now available!
      </Typography>

      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5 }}
      >
        <AppStoreButton
          url={"https://apps.apple.com/in/app/yourapp/6478938512"}
          theme={"light"}
          className={"custom-style"}
        />
        <GooglePlayButton
          url={"https://play.google.com/store/apps/details?id=co.trustmile"}
          theme={"light"}
          className={"custom-style"}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
        <Box sx={{ width: '200px', height: 'auto' }}> 
        <TrustmileLogo />
        </Box>
      </Box>

    </Box>
  );
};

export default ChangeLinkMessage;
