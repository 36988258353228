import { DocumentNode } from "@apollo/client";
import { getOperationDefinition } from "@apollo/client/utilities";
import { OperationMapType, SYNC_STATUS } from "interfaces/api-backup.interface";
import { NormalObject } from "interfaces/common.interfaces";
import { isEmpty } from "lodash";
import { API_OPERATION_MAP } from "./api.operation.map";
import { db, TableName } from "./db";
import {
  formatTableRow,
  generateObjectId,
  gqlQuery2Where,
  gqlQuery2WhereIn,
  localDbGetWhere,
  localDbUpdate,
} from "./local.db.utils";

export const backupAPIResponse = async (
  query: DocumentNode,
  variables: NormalObject = {},
  data: any
) => {
  const operation = getOperationDefinition(query);
  const operationName = operation?.name?.value ?? "";
  const value = data?.[operationName];
  const operationMap: OperationMapType = API_OPERATION_MAP?.[operationName];
  const tableName = operationMap.TableName;
  const isUpdate = operationMap.TYPE === "update";
  const isCreate = operationMap.TYPE === "create";

  // backup
  try {
    // ***********************************************************
    // make raw backup
    await db.api_backups.put(
      { name: operationName, value: value },
      operationName
    );
    // ***********************************************************

    //
    //
    // ***********************************************************
    // sync table

    if (tableName) {
      if (operation?.operation === "query") {
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            const item = value[i];
            const { formattedRow, rest } = formatTableRow(tableName, {
              ...item,
              ...variables,
            });

            // save main table
            await db[tableName].put(
              formattedRow,
              item[db[tableName].schema.primKey.name]
            );

            // save relative table
            if (!isEmpty(rest)) {
              for (let j = 0; j < Object.keys(rest).length; j++) {
                // @ts-ignore
                const relativeTableName: TableName = Object.keys(rest)[j];
                if (Array.isArray(rest[relativeTableName])) {
                  for (let k = 0; k < rest[relativeTableName].length; k++) {
                    const itemRow = rest[relativeTableName][k];
                    await db[relativeTableName].put(
                      itemRow,
                      itemRow[db[relativeTableName].schema.primKey.name]
                    );
                  }
                } else {
                  const item = rest[relativeTableName];
                  await db[relativeTableName].put(
                    item as any,
                    item[db[relativeTableName].schema.primKey.name as any]
                  );
                }
              }
            }
          }
        } else if (value && typeof value === "object") {
          await db[tableName].put(
            { ...value, ...variables },
            value[db[tableName].schema.primKey.name]
          );
        }
      }
      if (isCreate) {
        const insertData: any = { ...variables, _id: generateObjectId() };

        const ret = await db[tableName].add(insertData);

        return { [operationName]: ret };
      }
      if (isUpdate) {
        const ret = await localDbUpdate(tableName, query, variables);

        if (ret) {
          return { [operationName]: ret.length === 1 ? ret[0] : ret };
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const simulateMutation = async (
  query: DocumentNode,
  variables: NormalObject = {},
  backendSyncStatus: string = SYNC_STATUS.PENDING
) => {
  // set offline flag
  variables.backendSyncStatus = backendSyncStatus;

  const operation = getOperationDefinition(query);
  const operationName = operation?.name?.value ?? "";
  const operationMap: OperationMapType = API_OPERATION_MAP?.[operationName];
  const tableName = operationMap.TableName;

  const isUpdate = operationMap.TYPE === "update";
  const isCreate = operationMap.TYPE === "create";
  const isDelete = operationMap.TYPE === "delete";

  // backup
  if (operation?.operation === "mutation") {
    try {
      //
      //
      // ***********************************************************
      // sync table

      if (tableName) {
        if (isUpdate) {
          const ret = await localDbUpdate(tableName, query, variables);

          if (ret) {
            return { [operationName]: ret.length === 1 ? ret[0] : ret };
          }
        }

        if (isCreate) {
          const insertData: any = { ...variables, _id: generateObjectId() };

          const ret = await db[tableName].add(insertData);

          return { [operationName]: ret };
        }

        if (isDelete) {
          const primaryKey = variables[db[tableName].schema.primKey.name];
          const ret = await db[tableName].add(primaryKey);

          return { [operationName]: ret };
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return false;
};

export const restoreAPIResponse = async (
  query: DocumentNode,
  variables: NormalObject = {}
) => {
  const operation = getOperationDefinition(query);
  const operationName = operation?.name?.value ?? "";
  const operationMap: OperationMapType = API_OPERATION_MAP?.[operationName];
  const tableName = operationMap.TableName;

  if (operation?.operation === "query") {
    // ***********************************************************
    // restore from raw backup
    // const result = await db.api_backups.where({ name: operationName }).first();
    // ***********************************************************

    const formattedWhere = gqlQuery2Where(query, variables);

    const formattedWhereIn = gqlQuery2WhereIn(query, variables);

    if (isEmpty(formattedWhere)) {
      const result = await db[tableName].toArray();

      if (result) {
        return { [operationName]: result };
      }
    }

    const isGet = operationMap.TYPE === "get";

    const result = await localDbGetWhere(
      tableName,
      formattedWhere,
      formattedWhereIn
    );

    if (result) {
      if (isGet) {
        return { [operationName]: result[0] };
      }
      return { [operationName]: result };
    }
  }

  return false;
};

export async function deletePreviousData<T>(
  tableName: TableName
): Promise<void> {
  try {
    await db.table<T, number>(tableName).clear();
  } catch (error) {
    console.log(error);
  }
}
