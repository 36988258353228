import create from "zustand";

import jwt_decode from "jwt-decode";
import { User } from "interfaces/user.interface";
import { globalToken } from "graphql/client/client";

const decodeToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    globalToken.accessToken = token;
    const decodedToken: any = jwt_decode(token);
    if (decodedToken) {
      return decodedToken;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const deleteToken = () => {
  localStorage.removeItem("token");
  return null;
};

interface UserStore {
  user: User | null;
  isAuth: any;
  updateUser: (payload: Partial<User>) => void;
  updateIsAuth: () => void;
  logout: () => void;
}

const initialUser: User = {
  _id: "",
  email: "",
  name: "",
  role: "",
  image: "",
  truckRegistration: null,
  areas: [],
  defaultArea: null,
  last_name: "",
  phoneNumber: "",
  lastActive: null,
  organization: null,
};

export const useUserStore = create<UserStore>((set: any) => ({
  user: initialUser,
  isAuth: decodeToken(),
  updateUser: ({ ...payload }) => set((state: any) => ({ ...state, user: { ...state.user, ...payload } })),
  updateIsAuth: () => set((state: any) => ({ ...state, isAuth: decodeToken() })),
  logout: () =>
    set((state: any) => ({
      ...state,
      isAuth: deleteToken(),
      user: initialUser,
    })),
}));
