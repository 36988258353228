import { Area } from "./area.interface";
import { Organization } from "./organization.interface";
import { TruckRegistration } from "./truck-registration.interface";

export enum Roles {
  Basic = "Basic",
  Driver = "Driver",
  User = "User",
  Admin = "Admin",
}

export interface User {
  _id: string;
  email: string;
  name: string;
  role: string;
  image: string;
  truckRegistration: TruckRegistration | null;
  areas: Area[] | null;
  defaultArea: Area | null;
  last_name: string;
  phoneNumber: string | null;
  lastActive: string | null;
  organization: Organization | null;

  backendSyncStatus?: string;
}
