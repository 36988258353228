import create from 'zustand';
import { PaletteMode } from '@mui/material';
import persist from 'state/persist';

interface Setting {
  tabValue: number;
  mode: PaletteMode;
  themeColor: string;
  extend: boolean;
  dragAndDrop: boolean;
  pullToRefresh: boolean;
  setTabValue: (payload: any) => void;
  toggleMode: () => void;
  setThemeColor: (payload: any) => void;
  toggleExtend: () => void;
  toggleDragAndDrop: () => void;
  togglePullToRefresh: () => void;
}

export const useSettingStore = create<any>(
  persist<Setting>(
    {
      key: 'setting',
    },
    (set: any) => ({
      tabValue: 0,
      mode: 'light',
      themeColor: '#536DFE',
      dragAndDrop: true,
      extend: true,
      pullToRefresh: true,
      setTabValue: (payload) => set({ tabValue: payload }),
      toggleMode: () => set((state: any) => (state.mode === 'light' ? { mode: 'dark' } : { mode: 'light' })),
      setThemeColor: (payload: string) => set({ themeColor: payload }),
      toggleExtend: () => set((state: any) => ({ extend: !state.extend })),
      toggleDragAndDrop: () => set((state: any) => ({ dragAndDrop: !state.dragAndDrop })),
      togglePullToRefresh: () => set((state: any) => ({ pullToRefresh: !state.pullToRefresh })),
    })
  )
);
