import { ApolloClient, ApolloLink, DocumentNode, HttpLink, InMemoryCache, split } from "@apollo/client";
import { getMainDefinition, getOperationDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";

import { SYNC_STATUS } from "interfaces/api-backup.interface";
import { NormalObject } from "interfaces/common.interfaces";
import { Roles } from "interfaces/user.interface";
import { backupAPIResponse, restoreAPIResponse, simulateMutation } from "localDB/backupRestoreApi";
import { useUserStore } from "state/user.store";

export const globalToken = { accessToken: "" };

const origin = window.location.origin;
const webSocketLink =
  process.env.NODE_ENV === "development"
    ? "ws://localhost:3001/api/graphql"
    : `${origin.replace("https", "wss")}/api/graphql`;

console.log(webSocketLink);

const httpLink = new HttpLink({
  uri: "/api/graphql",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: webSocketLink,
    retryAttempts: 100,
    connectionParams: () => ({
      email: useUserStore.getState().user?.email,
    }),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

const authLink = new ApolloLink((operation: any, forward: any) => {
  if (!!globalToken.accessToken) {
    const decodedToken: any = jwt_decode(globalToken.accessToken);
    let currentDate = new Date();
    if (decodedToken) {
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.info("Token is expired!");
        toast.error("Session token expired. Please login again.", { duration: 4000 });
        globalToken.accessToken = "";
        useUserStore.getState().logout();
        return;
      }
    }
  }
  operation.setContext({
    headers: {
      authorization: globalToken.accessToken ? `Bearer ${globalToken.accessToken}` : "",
    },
  });
  return forward(operation);
});

export const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache(),
});

export const ExecGraphQL = async (
  query: DocumentNode,
  variables?: NormalObject,
  policy?: "cache-first" | "network-only" | "cache-only" | "no-cache" | "standby" | null,
  preferLocal: boolean = false,
  backendSyncStatus: string = SYNC_STATUS.PENDING
) => {
  const { user } = useUserStore.getState();
  const isDriver = user?.role === Roles.Driver;

  const operation = getOperationDefinition(query);
  const isQuery = operation?.operation === "query";
  const isMutation = operation?.operation === "mutation";

  const doLocalDb = async () => {
    if (isQuery) {
      const res = await restoreAPIResponse(query, variables?.input);
      if (res) {
        return { ...res, offline: true };
      }
    }

    if (isMutation) {
      const res = await simulateMutation(query, variables?.input, backendSyncStatus);
      if (res) {
        return { ...res, offline: true };
      }
    }
  };

  if (preferLocal && isDriver) {
    return await doLocalDb();
  }

  try {
    const { data } = await client.query({
      query,
      variables,
      fetchPolicy: policy || "no-cache",
    });

    if (isDriver) {
      // backup if query
      if (typeof data === "object" && data) {
        try {
          await backupAPIResponse(query, variables?.input, data);
        } catch (error) {}
      }
    }

    return data;
  } catch (err: any) {
    try {
      if (isDriver) {
        const res = await doLocalDb();
        if (res) {
          return res;
        }
      }

      return {
        error: true,
        message: (err && err.graphQLErrors?.[0]?.message) ?? "",
      };
    } catch (error) {
      return {
        error: true,
        message: (err && err.graphQLErrors?.[0]?.message) ?? "",
      };
    }
  }
};
