import { createTheme } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { useSettingStore } from 'state/setting.store';

const useTheme = () => {
  const { mode, themeColor } = useSettingStore();

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: themeColor,
      },
      error: {
        main: red[400],
      },
      success: {
        main: green[600],
      },
    },

    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(5px)',
            // backgroundColor: themeColor,
            // opacity: 0.1,
          },
        },
      },
    },
  });

  return theme;
};

export { useTheme };
