import { DocumentNode } from '@apollo/client';
import { getOperationDefinition } from '@apollo/client/utilities';
import { IndexSpec } from 'dexie';
import { DbUpdateData, OperationMapType } from 'interfaces/api-backup.interface';
import { NormalObject, StringArrayObject } from 'interfaces/common.interfaces';
import { API_OPERATION_MAP } from './api.operation.map';
import { db, TableName, TABLE_NAMES } from './db';

export const gqlQuery2Where = (query: DocumentNode, variables: NormalObject): NormalObject => {
  const operation = getOperationDefinition(query);
  const operationName = operation?.name?.value ?? '';
  const operationMap: OperationMapType = API_OPERATION_MAP?.[operationName];
  const tableName = operationMap.TableName;

  return Object.keys(variables).reduce((ret, keyName: string) => {
    const matchIndex = [db[tableName].schema.primKey, ...db[tableName].schema.indexes].findIndex(
      (index) => index.name === keyName
    );

    if (matchIndex >= 0) {
      return {
        ...ret,
        [keyName]: variables?.[keyName],
      };
    }

    return ret;
  }, {});
};

export const gqlQuery2WhereIn = (query: DocumentNode, variables: NormalObject): StringArrayObject => {
  const operation = getOperationDefinition(query);
  const operationName = operation?.name?.value ?? '';
  const operationMap: OperationMapType = API_OPERATION_MAP?.[operationName];
  const tableName = operationMap.TableName;

  return Object.keys(variables).reduce((ret, keyName: string) => {
    const matchIndex = [db[tableName].schema.primKey, ...db[tableName].schema.indexes].findIndex(
      (index) =>
        `${index.name}s` === keyName ||
        `${index.name}es` === keyName ||
        `${`${index.name}`.substring(0, -1)}ies` === keyName
    );

    if (matchIndex >= 0 && Array.isArray(variables?.[keyName])) {
      return {
        ...ret,
        [[db[tableName].schema.primKey, ...db[tableName].schema.indexes][matchIndex].name]: variables?.[keyName],
      };
    }
    return ret;
  }, {});
};

export const gqlQuery2UpdateData = (
  query: DocumentNode,
  variables: {
    [key: string]: any;
  }
): DbUpdateData => {
  // const operation = getOperationDefinition(query);
  // const operationName = operation?.name?.value ?? '';
  // const operationMap: OperationMapType = API_OPERATION_MAP?.[operationName];
  // const tableName = operationMap.TableName;

  return Object.keys(variables).reduce(
    (ret: DbUpdateData, keyName, keyNameIndex) => {
      // const matchIndex = [
      //   db[tableName].schema.primKey,
      //   ...db[tableName].schema.indexes,
      // ].findIndex(
      //   (index) =>
      //     `${index.name}s` === keyName ||
      //     `${index.name}es` === keyName ||
      //     `${`${index.name}`.substring(0, -1)}ies` === keyName ||
      //     index.name === keyName
      // );

      // if (matchIndex < 0) {
      //   return ret;
      // }

      if (keyNameIndex < 1) {
        ret.whereData[keyName] = variables[keyName];
      } else {
        ret.updateData[keyName] = variables[keyName];
      }
      return ret;
    },
    {
      whereData: {},
      updateData: {},
    }
  );
};

export const localDbGetWhere = async (tableName: TableName, where: NormalObject, whereIn: StringArrayObject) => {
  const relations = db[tableName].schema.indexes.reduce((ret: IndexSpec[], tableIndex) => {
    const matchIndex = TABLE_NAMES.findIndex(
      (tableNameItem) => tableNameItem === tableIndex.name || tableNameItem === `${tableIndex.name}s`
    );

    if (matchIndex >= 0) {
      ret.push(tableIndex);
    }

    return ret;
  }, []);

  const result = await db[tableName]
    .where(where)
    .and((row) =>
      Object.keys(whereIn).reduce((ret: boolean, keyName) => {
        const arr = whereIn[keyName];
        // @ts-ignore: Unreachable code error
        return ret && arr.some((item) => item === row?.[keyName]);
      }, true)
    )
    .toArray();

  if (relations.length) {
    const joinedResult = [];
    for (let i = 0; i < result.length; i++) {
      const row: any = result[i];
      for (let j = 0; j < relations.length; j++) {
        const relationIndex = relations[j];
        // @ts-ignore
        const relationName: TableName = relationIndex.name;
        if (Array.isArray(row[relationName])) {
          const rowRelationItems = await db[relationName]
            .where(db[relationName].schema.primKey.name)
            .anyOf(
              row[relationName].map((relationRowItem: any) => relationRowItem[db[relationName].schema.primKey.name])
            )
            .toArray();

          row[relationName] = rowRelationItems;
        } else {
          const tableName = `${relationName}s` as TableName;

          const rowRelationCustomer = await db[tableName]
            .where(db[tableName].schema.primKey.name)
            .equals(row[relationName][db[tableName].schema.primKey.name])
            .first();
          row[relationName] = rowRelationCustomer;
        }
      }

      joinedResult.push(row);
    }

    return joinedResult;
  }

  return result;
};

export const localDbUpdate = async (tableName: TableName, query: DocumentNode, variables: NormalObject = {}) => {
  const { whereData, updateData } = gqlQuery2UpdateData(query, variables);

  const formattedWhere = gqlQuery2Where(query, whereData);

  const formattedWhereIn = gqlQuery2WhereIn(query, whereData);

  const result = await localDbGetWhere(tableName, formattedWhere, formattedWhereIn);

  for (let index = 0; index < result.length; index++) {
    const row = result[index];
    // @ts-ignore
    await db[tableName].update(
      // @ts-ignore
      row[db[tableName].schema.primKey.name],
      {
        ...row,
        ...updateData,
      }
    );
  }

  const ret = await localDbGetWhere(tableName, formattedWhere, formattedWhereIn);

  return ret;
};

export const generateObjectId = (): string => {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const formatTableRow = (
  tableName: TableName,
  data: any
): { formattedRow: any; rest: { [key: string]: Array<any> } } => {
  return Object.keys(data).reduce(
    (ret, keyName) => {
      const item = data[keyName] ?? data[`${keyName}s`];
      if (Array.isArray(item) && TABLE_NAMES.some((tableNameItem) => tableNameItem === keyName)) {
        if (item.length) {
          // @ts-ignore
          const relativeTablePrimKeyName = db[keyName].schema.primKey.name;

          if (item[0][relativeTablePrimKeyName]) {
            ret.rest = { ...ret.rest, [keyName]: item };
            ret.formattedRow = {
              ...ret.formattedRow,
              [keyName]: item.map((itemRow) => ({
                [relativeTablePrimKeyName]: itemRow?.[relativeTablePrimKeyName] ?? '',
              })),
            };
            return ret;
          }
        }
      }
      if (typeof item === 'object' && TABLE_NAMES.some((tableNameItem) => tableNameItem === `${keyName}s`)) {
        if (item) {
          // @ts-ignore
          const tableName = `${keyName}s` as TableName;
          const relativeTablePrimKeyName = db[tableName].schema.primKey.name;

          if (item[relativeTablePrimKeyName]) {
            ret.rest = { ...ret.rest, [tableName]: item };
            ret.formattedRow = {
              ...ret.formattedRow,
              [keyName]: {
                [relativeTablePrimKeyName]: item?.[relativeTablePrimKeyName] ?? '',
              },
            };
            return ret;
          }
        }
      }

      ret.formattedRow = { ...ret.formattedRow, [keyName]: item };

      return ret;
    },
    { formattedRow: {}, rest: {} }
  );
};
