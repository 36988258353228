import { useContext, useEffect, useState, createContext, useCallback, useMemo } from "react";
import { useLazyQuery, useMutation, useQuery, useSubscription } from "@apollo/client";
import { IChat } from "interfaces/chat.interface";
import { CHAT_SUBSCRIPTION, CREATE_CHAT, GET_CHAT, LIST_UNREAD_CHATS, UPDATE_CHAT } from "graphql/gql/chat.gql";
import { useUserStore } from "state/user.store";
import { GET_USERS, ONLINE_STATUS } from "graphql/gql/user.gql";
import { listChats } from "graphql/api/chat.api";
import { User } from "interfaces/user.interface";

interface Props {
  children?: React.ReactNode;
}

export interface ChatContextType {
  conversations: IConversation[];
  conversationsOpened: IConversation[];
  conversationsMinimized: IConversation[];
  unreadMessages: IChat[];
  onLoadPrevious: (id: string) => Promise<void>;
  onClose: (id: string) => void;
  onMinimize: (id: string) => void;
  onSubmitText: (id: string, message: string) => Promise<void>;
  onSubmitImage: (id: string, image: string) => Promise<void>;
  openConversation: (id: string) => void;
  getUnreadMessages: () => void;
}

export interface IConversation {
  conversationId: string;
  lastActive: string | null;
  user: User;
  showAvatar: boolean;
  opened: boolean;
  messages: IChat[];
}

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({ children }: Props) {
  const user = useUserStore((state) => state.user);
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<IChat[]>([]);

  const { data: chatData } = useSubscription(CHAT_SUBSCRIPTION, {
    variables: { userId: user?._id },
    skip: !user?._id,
  });

  const { data: onlineStatusData } = useSubscription(ONLINE_STATUS, {
    variables: { userId: user?._id },
    skip: !user?._id,
  });

  const [getUnreadMessages] = useLazyQuery(LIST_UNREAD_CHATS, {
    onCompleted(data) {
      console.log(`Successfully queried ${data.listUnreadChats.length} unread messages.`);
      if (data.listUnreadChats.length) {
        setUnreadMessages(data.listUnreadChats);
        const newConversations = conversations.map((conversation) => {
          if (data.listUnreadChats.find((chat: IChat) => chat.sender._id === conversation.conversationId)) {
            conversation.opened = false;
            conversation.showAvatar = true;
          }

          return conversation;
        });

        setConversations(newConversations);
      }
    },
    onError(error) {
      console.log("Failed query unread messages::", error);
    },
    fetchPolicy: "no-cache",
  });

  const [getChat] = useLazyQuery(GET_CHAT, {
    onCompleted(data) {
      const newConversations = conversations.map((conversation) => {
        if (conversation.conversationId === data.getChat.sender._id) {
          conversation.messages.push({
            ...data.getChat,
          });
        }

        return conversation;
      });

      setConversations(newConversations);
    },
    fetchPolicy: "no-cache",
  });

  const { loading } = useQuery(GET_USERS, {
    variables: {
      input: {},
    },
    skip: !!conversations.length || !user?._id,
    onCompleted(data) {
      if (data.getUsers.length) {
        let initialConversations = [];
        for (const contact of data.getUsers) {
          if (contact._id !== user?._id) {
            initialConversations.push({
              conversationId: contact._id,
              lastActive: contact.lastActive,
              user: contact,
              opened: false,
              showAvatar: false,
              messages: [],
            });
          }
        }
        setConversations(initialConversations);
      }
    },
    onError(error) {
      console.log("Error getting users", error);
    },
  });

  const [sendMessage] = useMutation(CREATE_CHAT, {
    onCompleted(data) {
      const newConversations = [...conversations].map((conversation) => {
        if (conversation.conversationId === data.createChat.recipient._id) {
          conversation.messages.push({
            ...data.createChat,
          });
        }

        return conversation;
      });

      setConversations(newConversations);
    },
    onError(error) {
      console.log("error:", error);
    },
  });

  const [updateMessage] = useMutation(UPDATE_CHAT, {
    onCompleted(data) {
      console.log("Successfully updated chat message read status.");
      setUnreadMessages((prev) => [...prev.filter((message) => message._id !== data.updateChat._id)]);
    },
    onError(error) {
      console.log("Failed update chat message read status::", error);
    },
  });

  useEffect(() => {
    if (chatData) {
      if (conversationsOpened.find((c) => c.conversationId === chatData.chatSubscription.sender._id)) {
        getChat({ variables: { _id: chatData.chatSubscription._id } });
      } else {
        getUnreadMessages();
      }
    }
  }, [chatData]);

  useEffect(() => {
    if (onlineStatusData) {
      console.log(`Received online/offline status update from user: ${onlineStatusData.onlineStatus._id}`);
      const newConversations = [...conversations].map((conversation) => {
        if (conversation.conversationId === onlineStatusData.onlineStatus._id) {
          return { ...conversation, lastActive: onlineStatusData.onlineStatus.lastActive };
        }

        return conversation;
      });

      setConversations(newConversations);
    }
  }, [onlineStatusData]);

  const handleUpdateUnreadMessages = async (chats: IChat[]) => {
    const unreadChats = chats.filter((chat: IChat) => chat.recipient._id === user?._id && !chat.read);
    if (unreadChats.length) {
      for (const chat of unreadChats) {
        updateMessage({
          variables: {
            input: {
              _id: chat._id,
              read: true,
            },
          },
        });
      }
    }
  };

  const onLoadPrevious = async (conversationId: string) => {
    try {
      const messagesCount = conversations.find((c) => c.conversationId === conversationId)?.messages.length;

      const chats = await listChats({ sender: conversationId, skip: messagesCount });

      const newConversations = conversations.map((conversation) => {
        if (conversation.conversationId === conversationId) {
          conversation.messages = [...chats.listChats.reverse(), ...conversation.messages];
        }

        return conversation;
      });
      setConversations(newConversations);
      handleUpdateUnreadMessages(chats.listChats);
    } catch (error) {
      console.error(error);
    }
  };

  const conversationsOpened = useMemo(() => {
    return conversations.filter((c) => c.opened && c.showAvatar);
  }, [conversations]);

  const conversationsMinimized = useMemo(() => {
    return conversations.filter((c) => !c.opened && c.showAvatar);
  }, [conversations]);

  const onClose = (id: string) => {
    const newConversations = conversations.map((conversation) => {
      if (conversation.conversationId === id) {
        conversation.opened = false;
        conversation.showAvatar = false;
        conversation.messages = [];
      }

      return conversation;
    });

    setConversations(newConversations);
  };

  const onMinimize = (id: string) => {
    const newConversations = conversations.map((conversation) => {
      if (conversation.conversationId === id) {
        conversation.opened = false;
        conversation.messages = [];
      }

      return conversation;
    });

    setConversations(newConversations);
  };

  const openConversation = async (id: string) => {
    let totalOpened = 0;

    const chats = await listChats({ sender: id, skip: 0 });

    const newConversations = conversations.map((conversation) => {
      if (totalOpened === 2) {
        conversation.opened = false;
      }

      if (conversation.opened) {
        totalOpened += 1;
      }

      if (conversation.conversationId === id) {
        conversation.opened = true;
        conversation.showAvatar = true;
        conversation.messages = chats.listChats.reverse();
      }

      return conversation;
    });

    setConversations(newConversations);

    handleUpdateUnreadMessages(chats.listChats as IChat[]);
  };

  const onSubmitText = useCallback(async (conversationId: string, message: string) => {
    await sendMessage({
      variables: { input: { text: message, messageType: "text", recipient: conversationId } },
    });
  }, []);

  const onSubmitImage = useCallback(async (conversationId: string, image: string) => {
    await sendMessage({
      variables: { input: { image, messageType: "image", recipient: conversationId } },
    });
  }, []);

  return (
    <ChatContext.Provider
      value={{
        conversations,
        conversationsOpened,
        conversationsMinimized,
        unreadMessages,
        onLoadPrevious,
        onClose,
        onMinimize,
        onSubmitText,
        onSubmitImage,
        openConversation,
        getUnreadMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error(`useChat must be used within a MyChatContextProvider.`);
  }
  return context;
};
