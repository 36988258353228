import { TableName } from 'localDB/db';
import { NormalObject } from './common.interfaces';

export interface ApiBackup {
  name: string;
  value: any;
}

export interface DbUpdateData {
  whereData: NormalObject;
  updateData: NormalObject;
}

export interface OperationMapType {
  TableName: TableName;
  TYPE: 'create' | 'update' | 'get' | 'list' | 'delete';
}

export const SYNC_STATUS = {
  PENDING: 'SYNC_PENDING',
  OK: 'SYNC_OK',
};
