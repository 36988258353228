import { User } from "interfaces/user.interface";

export const convertArrayToObject = (array: any, key: string) => {
  const initialValue = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item.Item,
    };
  }, initialValue);
};

export const formatCurrencyNumber = (value: any) => {
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const getRandomColor = () => {
  return "#" + (Math.random().toString(16) + "000000").substring(2, 8);
};
export const getPercentage = (partialValue: any, totalValue: any) => {
  return ((100 * Number(partialValue)) / Number(totalValue)).toFixed(2);
};

export const getInitialName = (user: User) => {
  const firstName = user?.name?.split(" ")[0] ?? "";
  const lastName = user?.last_name?.split(" ")[0] ?? "";
  return `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;
};

export const getFullName = (user: User) => {
  const firstName = user?.name ?? "";
  const lastName = user?.last_name ?? "";
  return `${firstName} ${lastName}`;
};

export const capitalizeFirstLetter = (words?: string) => {
  let newString = words?.replace(/\b[A-Z]/gi, function (letter: string) {
    return letter.toUpperCase();
  });
  return newString;
};

//For multiple delivery photos or multiple items with issue, group into photos per page
export function groupByTwo(arr: any[]): any[][] | null {
  const result: any[][] = [];

  if (!arr) return null;

  for (let i = 0; i < arr.length; i += 2) {
    if (i + 1 < arr.length) {
      result.push([arr[i], arr[i + 1]]);
    } else {
      result.push([arr[i]]);
    }
  }

  return result;
}

export async function isInternetSpeedOK(): Promise<boolean> {
  let startTime: number;
  let endTime: number;
  let imageSize: number;
  let response: Response | undefined;

  const calculateInternetSpeed = () => {
    //Time taken in seconds
    const timeDuration = (endTime - startTime) / 1000;
    //total bits
    const loadedBits = imageSize * 8;
    const speedInBps = Number((loadedBits / timeDuration).toFixed(2));
    const speedInKbps = Number((speedInBps / 1024).toFixed(2));
    const speedInMbps = Number((speedInKbps / 1024).toFixed(2));
    console.log("speedInMbps", speedInMbps);
    return speedInMbps;
  };
  //Get image size
  startTime = new Date().getTime();
  try {
    response = await fetch("https://source.unsplash.com/random?topics=nature");
  } catch (error) {
    console.log("calculateInternetSpeed error:", error);
    return false;
  }
  endTime = new Date().getTime();
  imageSize = Number(response?.headers.get("content-length") || "0");
  return calculateInternetSpeed() > 0.5;
}

export const getDeviceOS = () => {
  if (/windows/i.test(navigator.userAgent)) {
    return "Windows";
  } else if (/iphone/i.test(navigator.userAgent)) {
    return "iOS";
  } else if (/ipad/i.test(navigator.userAgent)) {
    return "iOS";
  } else if (/macintosh/i.test(navigator.userAgent)) {
    return "Mac OS";
  } else if (/android/i.test(navigator.userAgent)) {
    return "Android";
  } else {
    return "Unknown";
  }
};
