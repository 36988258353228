import React, { useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import ChatBoxHeader from "./ChatBoxHeader";
import ChatBoxMessage from "./ChatBoxMessage";
import ChatBoxFooter from "./ChatBoxFooter";
import { Box } from "@mui/material";
import { IChat } from "interfaces/chat.interface";

export interface ChatBoxProps {
  conversationId: string;
  title: string;
  lastActive: string | null;
  conversations: IChat[];
  onClose: (id: string) => void;
  onMinimize: (id: string) => void;
  onSubmitText: (id: string, message: string) => Promise<void>;
  onSubmitImage: (id: string, message: string) => Promise<void>;
  onLoadPrevious: (id: string) => void;
  // onRemove: (conversationId: string, messageId: string) => void;
}

const ChatBox: React.FC<ChatBoxProps> = ({
  conversations,
  conversationId,
  lastActive,
  onClose,
  onMinimize,
  onSubmitText,
  onSubmitImage,
  onLoadPrevious,
  // onRemove,
  title,
}) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  // const { onGetChats } = useChat();

  // const handleGetChats = async () => {
  //   console.log("GETTING CHATS", conversations);
  //   await onGetChats(conversationId, 0);
  //   scrollToBottom();
  // };

  useEffect(() => {
    // handleGetChats();
    scrollToBottom();
    const conversation = document.getElementById(`conversation-${conversationId}`);

    conversation?.addEventListener("scroll", (e: any) => {
      const el = e.target;

      if (el.scrollTop === 0) {
        onLoadPrevious(conversationId);
      }
    });
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSubmitMessage = async (message: string) => {
    await onSubmitText(conversationId, message);
    scrollToBottom();
  };

  const onSubmitBase64Image = async (image: string) => {
    await onSubmitImage(conversationId, image);
    scrollToBottom();
  };

  return (
    <Card variant="outlined" sx={{ borderRadius: 3, width: "350px" }}>
      <ChatBoxHeader
        title={title}
        onClose={() => onClose(conversationId)}
        onMinimize={() => onMinimize(conversationId)}
        lastActive={lastActive}
      />
      <Box sx={{ p: 1, height: "350px", overflow: "auto", width: "100%" }} id={`conversation-${conversationId}`}>
        {conversations.map((conversation, index) => {
          return (
            <ChatBoxMessage
              key={conversation._id}
              messageId={conversation._id}
              message={conversation}
              author={conversation.sender}
              showAvatar={
                index === conversations.length - 1 || conversation.sender._id !== conversations[index + 1]?.sender._id
              }
              // onRemove={(messageId: string) => onRemove(conversationId, messageId)}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </Box>
      <ChatBoxFooter onSubmitText={onSubmitMessage} onSubmitImage={onSubmitBase64Image} />
    </Card>
  );
};

export default ChatBox;
