import gql from "graphql-tag";

export const REGISTER_USER = gql`
  mutation register($input: CreateUserInput!) {
    register(input: $input) {
      _id
    }
  }
`;

export const REGISTER_USER_ADMIN = gql`
  mutation register($input: CreateUserInput!) {
    register(input: $input) {
      _id
      email
      name
      last_name
      role
      image
      phoneNumber
      truckRegistration(populate: true) {
        _id
        truckRegistration
      }
      areas(populate: true) {
        _id
        area
      }
      defaultArea(populate: true) {
        _id
        area
      }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const GET_USER = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      _id
      email
      name
      last_name
      role
      truckRegistration(populate: true) {
        _id
        truckRegistration
      }
      areas(populate: true) {
        _id
        area
      }
      defaultArea(populate: true) {
        _id
        area
      }
      organization(populate: true) {
        _id
        name
        integration(populate: false) {
          _id
        }
      }
      image
      phoneNumber
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($input: QueryUserInput!) {
    getUsers(input: $input) {
      _id
      email
      name
      last_name
      role
      truckRegistration(populate: true) {
        _id
        truckRegistration
      }
      areas(populate: true) {
        _id
        area
      }
      defaultArea(populate: true) {
        _id
        area
      }
      image
      phoneNumber
      lastActive
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    UpdateUser(updateUserInput: $updateUserInput) {
      _id
      name
      email
      role
      truckRegistration(populate: true) {
        _id
        truckRegistration
      }
      areas(populate: true) {
        _id
        area
      }
      defaultArea(populate: true) {
        _id
        area
      }
      image
      phoneNumber
      lastActive
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($deleteUserInput: DeleteUserInput!) {
    DeleteUser(deleteUserInput: $deleteUserInput) {
      _id
      name
      email
      image
    }
  }
`;

export const DELETE_MANY_USERS = gql`
  mutation DeleteManyUsers($deleteManyUsersInput: DeleteManyUsersInput!) {
    DeleteManyUsers(deleteManyUsersInput: $deleteManyUsersInput) {
      _id
    }
  }
`;

export const FORGOT_PASSWORD_QL = gql`
  mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    ForgotPassword(forgotPasswordInput: $forgotPasswordInput) {
      success
      message
    }
  }
`;

export const RESET_PASSWORD_QL = gql`
  mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
    ResetPassword(resetPasswordInput: $resetPasswordInput)
  }
`;

export const ONLINE_STATUS = gql`
  subscription onlineStatus($userId: String!) {
    onlineStatus(userId: $userId) {
      _id
      lastActive
    }
  }
`;
