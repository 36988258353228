import { useChat } from "providers/ChatContext";
import { Box, Tooltip } from "@mui/material";
import ChatBox from "./ChatBox";
import AvatarWithUsername from "./AvatarWithUsername";
import { getFullName } from "utils/common.utils";

function ChatBoxList() {
  const {
    openConversation,
    conversationsOpened,
    conversationsMinimized,
    onClose,
    onMinimize,
    onSubmitText,
    onSubmitImage,
    onLoadPrevious,
    unreadMessages,
  } = useChat();

  return (
    <Box sx={{ position: "fixed", bottom: 0, right: 0, zIndex: 2 }}>
      <Box sx={{ display: "flex", gap: 3, position: "fixed", bottom: 12, right: 80 }}>
        {conversationsOpened.map((conversation) => {
          return (
            <ChatBox
              conversationId={conversation.conversationId}
              key={conversation.conversationId}
              title={getFullName(conversation.user)}
              conversations={conversation.messages}
              lastActive={conversation.lastActive}
              onClose={onClose}
              onMinimize={onMinimize}
              onSubmitText={onSubmitText}
              onSubmitImage={onSubmitImage}
              onLoadPrevious={onLoadPrevious}
              // onRemove={onRemove}
            />
          );
        })}
      </Box>
      {!!conversationsMinimized.length && (
        <Box
          sx={{
            position: "fixed",
            bottom: 12,
            right: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: 1,
            py: 2,
            borderRadius: 5,
          }}
        >
          {conversationsMinimized.map((conversation) => {
            return (
              <Tooltip placement="right-start" key={conversation.conversationId} title={getFullName(conversation.user)}>
                <Box sx={{ cursor: "pointer" }} onClick={() => openConversation(conversation.conversationId)}>
                  <AvatarWithUsername
                    username={getFullName(conversation.user)}
                    hiddenName={true}
                    badgeVariant={conversation.lastActive ? "standard" : "dot"}
                    unreadCount={unreadMessages.filter((u) => u.sender._id === conversation.conversationId).length}
                  />
                </Box>
              </Tooltip>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default ChatBoxList;
