import React, { useEffect, useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import Send from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";

export interface ChatBoxFooterProps {
  onSubmitText: (message: string) => void;
  onSubmitImage: (image: string) => void;
}

const ChatBoxFooter: React.FC<ChatBoxFooterProps> = ({ onSubmitText, onSubmitImage }) => {
  const [message, setMessage] = useState("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      handleFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  const handleCompressImage = async (image: File) => {
    try {
      const compressedImage = await imageCompression(image, { maxSizeMB: 0.5 });
      return compressedImage;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFiles = async (files: File[]) => {
    for (const file of files) {
      const compressedImage = await handleCompressImage(file);
      const base64Image = await readFileAsDataURL(compressedImage as File);
      onSubmitImage(base64Image);
      console.log(base64Image);
    }
  };

  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const submitMessage = () => {
    if (!message) {
      return;
    }

    setMessage("");
    onSubmitText(message);
  };

  return (
    <Box sx={{ display: "flex", px: 0.5, pb: 0.5 }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton>
          <ImageIcon />
        </IconButton>
      </div>

      <TextField
        placeholder="Message here ... "
        size="small"
        fullWidth
        value={message}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            submitMessage();
          }
        }}
        InputProps={{
          sx: {
            borderRadius: 3,
          },
        }}
      />
      <IconButton onClick={submitMessage}>
        <Send />
      </IconButton>
    </Box>
  );
};

export default ChatBoxFooter;
