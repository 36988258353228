import { Box, ClickAwayListener, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import MoreVert from "@mui/icons-material/MoreVert";
import AvatarWithUsername from "./AvatarWithUsername";
import { blue, grey } from "@mui/material/colors";
import { useUserStore } from "state/user.store";
import moment from "moment";
import { IChat } from "interfaces/chat.interface";
import { User } from "interfaces/user.interface";
import { getFullName } from "utils/common.utils";

export interface MessageProps {
  messageId: string;
  message: IChat;
  author?: User;
  showAvatar?: boolean;
  onRemove?: (messageId: string) => void;
  onEdit?: (messageId: string, message: string) => void;
}

const ChatBoxMessage: React.FC<MessageProps> = ({
  messageId,
  message,
  // createdAt,
  author,
  // avatar,
  onEdit,
  showAvatar = false,
  onRemove,
}) => {
  const [actionMessageId, setActionMessageId] = useState<string | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user = useUserStore((state) => state.user);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMouseLeave = () => {
    setActionMessageId(undefined);
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setActionMessageId(undefined);
  };

  const removeMessage = () => {
    handleClose();
    onRemove && onRemove(messageId);
  };

  if (author?._id !== user?._id) {
    return (
      <Box sx={{ display: "flex", alignItems: "flex-end", gap: 0.5, my: 1, pl: showAvatar ? 0 : 3.5 }}>
        {showAvatar && (
          <AvatarWithUsername username={getFullName(author!)} hiddenName={true} badgeVariant="standard" size="small" />
        )}
        <Box
          sx={{
            overflow: "visible",
            bgcolor: grey[500],
            wordBreak: "break-word",
            color: "white",
            p: 1,
            maxWidth: "80%",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {message.messageType === "text" ? (
            <Typography>{message.text}</Typography>
          ) : (
            <img src={message.imageUrl} width={100} style={{ borderRadius: 12 }} />
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography sx={{ fontSize: 10 }}>{moment(message.createdAt).fromNow()}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-end", my: 1, maxWidth: "98%" }}
      onMouseEnter={() => setActionMessageId(messageId)}
      onMouseLeave={onMouseLeave}
    >
      {/* {actionMessageId === messageId && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton size="small" onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: 100,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={removeMessage}>Delete</MenuItem>
            </Menu>
          </Box>
        </ClickAwayListener>
      )} */}
      <Box
        sx={{
          bgcolor: blue[600],
          color: "white",
          p: 1,
          maxWidth: "80%",
          wordBreak: "break-word",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {message.messageType === "text" ? (
          <Typography>{message.text}</Typography>
        ) : (
          <img src={message.imageUrl} width={100} style={{ borderRadius: 12 }} />
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography sx={{ fontSize: 10 }}>{moment(message.createdAt).fromNow()}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatBoxMessage;
