import { gql } from "@apollo/client";

export const CREATE_CHAT = gql`
  mutation createChat($input: CreateChatInput!) {
    createChat(input: $input) {
      _id
      text
      image
      imageUrl
      messageType
      sender(populate: true) {
        _id
        name
        last_name
      }
      recipient(populate: true) {
        _id
        name
        last_name
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_CHATS = gql`
  query listChats($input: ListChatInput!) {
    listChats(input: $input) {
      _id
      text
      image
      imageUrl
      messageType
      read
      sender(populate: false) {
        _id
        name
        last_name
      }
      recipient(populate: false) {
        _id
        name
        last_name
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_UNREAD_CHATS = gql`
  query listUnreadChats {
    listUnreadChats {
      _id
      text
      image
      messageType
      sender(populate: true) {
        _id
        name
        last_name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CHAT = gql`
  query getChat($_id: String!) {
    getChat(_id: $_id) {
      _id
      text
      image
      imageUrl
      messageType
      read
      sender(populate: false) {
        _id
        name
        last_name
      }
      recipient(populate: false) {
        _id
        name
        last_name
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CHAT = gql`
  mutation updateChat($input: UpdateChatInput!) {
    updateChat(input: $input) {
      _id
      read
    }
  }
`;

export const CHAT_SUBSCRIPTION = gql`
  subscription chatSubscription($userId: String!) {
    chatSubscription(userId: $userId) {
      _id
      text
      messageType
      sender(populate: false) {
        _id
      }
    }
  }
`;
