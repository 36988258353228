import { FC } from "react";
import AvatarWithUsername from "./AvatarWithUsername";
import { Box, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Remove";
import moment from "moment";

interface ChatBoxHeaderProps {
  title: string;
  lastActive: string | null;
  onClose: () => void;
  onMinimize: () => void;
}

const ChatBoxHeader: FC<ChatBoxHeaderProps> = ({ title, lastActive, onClose, onMinimize }) => {
  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        justifyContent: "space-between",
        width: "100%",
        color: "white",
        backgroundColor: "gray",
      }}
    >
      <AvatarWithUsername
        username={title}
        badgeVariant={lastActive ? "standard" : "dot"}
        subTitle={lastActive ? `Active ${moment(lastActive).fromNow()}` : "Active now"}
      />
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={onMinimize}>
          <Remove sx={{ color: "white" }} />
        </IconButton>
        <IconButton onClick={onClose}>
          <Close sx={{ color: "white" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatBoxHeader;
