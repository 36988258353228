import { PersistGate } from "zustand-persist";

import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";

import { useTheme } from "hooks/useTheme";
import AppRoutes from "routes/AppRoutes";
import { useEffect, useState } from "react";
import { useUserStore } from "state/user.store";
import LogRocket from "logrocket";
import { useServiceWorker } from "hooks/useServiceWorker";
import ServiceWorkerUpdateNotification from "components/common/ServiceWorkerUpdateNotification";
import { ApolloProvider } from "@apollo/client";
import { client } from "graphql/client/client";
import { Toaster } from "react-hot-toast";
import { ChatProvider } from "providers/ChatContext";
import ChatBoxList from "components/admin/desktop/chat/ChatBoxList";
import { Roles } from "interfaces/user.interface";
import ChangeLinkMessage from "pages/common/ChangeLinkMessage";

const App = () => {
  const theme = useTheme();
  const { user } = useUserStore();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [openUpdateNotif, setOpenUpdatenotif] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.host.includes("uat.delivery-app.coreform.co")) {
      console.log("Initializing Logrocket UAT");
      LogRocket.init("qlhjwl/cowo-delivery-app-uat");
      if (user?.email) {
        LogRocket.identify(user?._id ?? "", {
          name: user?.name ?? "",
          email: user?.email ?? "",
        });
      }
    } else if (window.location.host.includes("delivery-app.coreform.co")) {
      console.log("Initializing Logrocket PROD");
      LogRocket.init("qlhjwl/cowo-delivery-app-prod");
      if (user?.email) {
        LogRocket.identify(user?._id ?? "", {
          name: user?.name ?? "",
          email: user?.email ?? "",
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setOpenUpdatenotif(true);
    } else {
      setOpenUpdatenotif(false);
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider theme={theme}>
          <PersistGate>
            <ChatProvider>
               <ChangeLinkMessage />
              {/* <AppRoutes /> */}
              {user?.role === Roles.Admin && <ChatBoxList />}
              <ServiceWorkerUpdateNotification
                open={openUpdateNotif}
                reloadPage={reloadPage}
                handleClose={() => setOpenUpdatenotif(false)}
              />
              <Toaster position="top-left" />
            </ChatProvider>
          </PersistGate>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
};

export default App;
